<template>
  <div></div>
</template>

<script>
// 引入API
import {loginS2S} from "../../api/HttpService";

export default {
  data: function () {
    return {};
  },

  mounted: async function () {
    console.log(this.$route.query);
    if (this.$route.query.sessionId) {
      localStorage.removeItem("sessionId");
      let params = {
        chlAppId: "com.brightcns.dxlc.wxmp",
        chlSid: this.$route.query.sessionId,
      };
      await loginS2S(params)
        .then(({ res }) => {
          if (
            res.originalData.bizCode == "SUCCESS" &&
            res.originalData.code == "SUCCESS"
          ) {
            window.localStorage.setItem("sessionId", res.data.sessionId);
            window.localStorage.setItem("hostProgram", "wxmp");
            let _url =
                window.location.host.match(/test/) == null
                    ? "https://h5-lzgj.brightcns.com/dxlc-discount/#/DiscountHomePage?from=bus"
                    : "https://lzgj.test.brightcns.cn/dxlc-discount/#/DiscountHomePage?from=bus";
            window.location.replace(_url) ;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {},
};
</script>

<style>
</style>

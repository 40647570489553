import {API} from './HttpConfig'
import http from './HttpUtil'
import * as Gateway from './GatewayConfig'


//打包发布环境为true 开发环境为false
const IS_RELEASE = true;
const apiHost = IS_RELEASE ? '/gateway' : 'https://lzgj.test.brightcns.cn/gateway';


// 获取网关参数
function getGatewayParams(method, params) {
    const obj = {}
    obj.appId = Gateway.appId
    obj.signType = Gateway.signType
    obj.sign = Gateway.sign
    obj.version = Gateway.version
    obj.sessionId = localStorage.getItem('sessionId') ? localStorage.getItem('sessionId') : ''
    // obj.sessionId = "7714695640864bace77caeb41d06b305"
    obj.timestamp = Date.parse(new Date())
    obj.method = method
    obj.bizReq = JSON.stringify(params)
    return obj
}

// appId=>h5
const POST = (method, params) => {
    const param = getGatewayParams(method, params)
    return http.post(apiHost, param)
}

// 获取的数据用bigString解析
const POST1 = (method, params) => {
    const param = getGatewayParams(method, params)
    return http.post1(apiHost, param)
}

// 查询生效卡
export const validCard = (param) => {
    return POST(API.validCard, param)
}
// 我的卡券
export const benefitQuery = (param) => {
    return POST(API.benefitQuery, param)
}
// 卡券底部选项列表
export const benefitItem = (param) => {
    return POST(API.benefitItem, param)
}
// 设置默认优惠方式
export const benefitDefault = (param) => {
    return POST(API.benefitDefault, param)
}
//优惠券
export const personalCoupon = (param) => {
    return POST(API.personalCoupon, param)
}

//获取折扣卡订单
export const orderQuery = (param) => {
    return POST1(API.orderQuery, param)
}

//折扣卡订单详情
export const orderDetail = (param) => {
    return POST1(API.orderDetail, param)
}

//角标数量
export const kindCount = (param) => {
    return POST1(API.kindCount, param)
}

// 通过小程序sessionId换取sessionId
export const loginS2S = (param) => {
    return POST(API.loginS2S, param);
}






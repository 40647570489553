<template>
  <div>
    <transition name="fade">
      <!-- <loading v-show="loading"></loading> -->
    </transition>
  </div>
</template>

<script>
// 单独引用axios
import axios from "axios";

export default {
  mounted: async function () {
    // 先判断有没有上次残留的sessionId
    window.localStorage.setItem("hostProgram", "DXLCAPP");
    if (window.localStorage.getItem("sessionId") != null) {
      console.log("有发现有sessionId啊"+window.localStorage.getItem("sessionId"))
      await this.$router.replace({
        name: "DiscountHomePage",
      });
    }
    else {
      // 查看是否有authCode和clientId
      let authCode = this.GetQueryString("authCode");
      let clientId = this.GetQueryString("clientId");
      if (authCode && clientId) {
        // 已授权
        var url =
            window.location.host.match(/test/) == null
                ? "/static/api/oauth/accessToken"
                : "/static/api/oauth/accessToken2";
        // 获取sessionId
        let params = {
          authCode: authCode,
          clientId: clientId,
          grantType: "authorization_code",
        };
        var queryString = [];
        for (var key in params) {
          queryString.push("".concat(key, "=").concat(params[key]));
        }

        if (queryString.length > 0) {
          queryString = queryString.join("&");
          url += "?".concat(queryString);
        }
        await axios.get(url).then((res) => {
          if (res.data.bizCode == "SUCCESS" && res.data.code == "SUCCESS") {
            // 拿到了sessionId
            var result = JSON.parse(res.data.bizResp);
            window.localStorage.setItem("sessionId", result.sessionId);
            console.log("换取成功"+this.getNeedStr())
            if (this.getNeedStr() == "bus"){
              setTimeout(() => {
                let _url =
                    window.location.host.match(/test/) == null
                        ? "https://h5-lzgj.brightcns.com/dxlc-discount/#/DiscountHomePage?from=bus"
                        : "https://lzgj.test.brightcns.cn/dxlc-discount/#/DiscountHomePage?from=bus";
                window.location.replace(_url) ;
              }, 0);
              return
            }
            this.$router.replace({
              name: "DiscountHomePage",
            });
          } else {
            // 换取sessionId失败 重新授权
            console.log("sessionId换取失败");
            //判断是否是乘车码页进入
            if (this.getParams("from") == "bus"){
              setTimeout(() => {
                let _url =
                    window.location.host.match(/test/) == null
                        ? "https://open.brightcns.com/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Fh5-lzgj.brightcns.com%2Fdxlc-discount%2F%23%2FOauthPage%3Ffrom%3Dbus&state=123"
                        : "https://open.test.brightcns.cn/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Flzgj.test.brightcns.cn%2Fdxlc-discount%2F%23%2FOauthPage%3Ffrom%3Dbus&state=123";
                window.location.href = _url;
              }, 0);
            }else{
              setTimeout(() => {
                let _url =
                    window.location.host.match(/test/) == null
                        ? "https://open.brightcns.com/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Fh5-lzgj.brightcns.com%2Fdxlc-discount%2F%23%2FOauthPage&state=123"
                        : "https://open.test.brightcns.cn/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Flzgj.test.brightcns.cn%2Fdxlc-discount%2F%23%2FOauthPage&state=123";
                window.location.href = _url;
              }, 0);
            }

          }
        });
      } else {
        // 未授权
        // console.log("未授权");
        if (this.getParams("from") == "bus"){
          setTimeout(() => {
            let _url =
                window.location.host.match(/test/) == null
                    ? "https://open.brightcns.com/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Fh5-lzgj.brightcns.com%2Fdxlc-discount%2F%23%2FOauthPage%3Ffrom%3Dbus&state=123"
                    : "https://open.test.brightcns.cn/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Flzgj.test.brightcns.cn%2Fdxlc-discount%2F%23%2FOauthPage%3Ffrom%3Dbus&state=123";
            window.location.href = _url;
          }, 0);
        } else{
          setTimeout(() => {
            let _url =
                window.location.host.match(/test/) == null
                    ? "https://open.brightcns.com/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Fh5-lzgj.brightcns.com%2Fdxlc-discount%2F%23%2FOauthPage&state=123"
                    : "https://open.test.brightcns.cn/api/oauth/authorize?clientId=4825428266737207706&scope=auth_base&redirectUri=https%3A%2F%2Flzgj.test.brightcns.cn%2Fdxlc-discount%2F%23%2FOauthPage&state=123";
            window.location.href = _url;
          }, 0);
        }

      }
    }
  },
  methods: {
    GetQueryString: function (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getParams: function (name) {
      var search = window.location.search.substring(1)
      if (!search) {
        search = window.location.hash.split('?')[1]
      }
      if (search) {
        var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        return name ? obj[name] : obj
      }
    },
    //获取最后一个=后的字符串
    getNeedStr: function (){
      var s = window.location.href;
      return s.substring(s.lastIndexOf("=")+1);
    }

  },
};
</script>

<style>
</style>

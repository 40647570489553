<template>
  <div class="CouponListPage">
    <div class="Nav">
      <!-- <div class="NavList" v-for="(value, key) in navList.list" :key="key">
        <span
            @click="changeNav(key)"
            ref="navList"
            :class="{ active: navList.nowSelectedNo == key }"
        >{{ value }}</span
        >
      </div>
      <div
          class="NavBottomLine"
          id="navBottomLine"
          :style="navBottomLine"
      ></div> -->
      <div class="navContainer">
        <div
          class="NavList"
          v-for="(value, key) in navList.list"
          :key="key"
          @click="changeNav(key)"
        >
          <div
            class="navText"
            :class="{ active: navList.nowSelectedNo == key }"
          >
            {{ value }}
          </div>
          <div class="navBottomLine" v-if="navList.nowSelectedNo == key"></div>
        </div>
      </div>
    </div>
    <div class="couponList">
      <!-- 列表 -->
      <div class="list">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          class="van-pull-refresh"
        >
          <van-list
            class="dataList"
            v-model="loading"
            :finished="finished"
            @load="onLoad"
            v-if="busList.length > 0"
            offset="300"
          >
            <div class="itemBox" v-for="(value, key) in busList" :key="key">
              <div class="dataItem" :class="isVipStyle(false, value.count)">
                <p class="vipTag">
                  <span> {{value.activityName}} </span>
                  <img src="../../assets/icon_cheng.png" />
                  <span>{{ value.count }}张</span>
                </p>
                <div class="dataItemBox">
                  <!--              优惠券信息-->
                  <div>
                    <!--                  <img-->
                    <!--                      class="icon"-->
                    <!--                      v-bind:src="value.icon">-->
                    <div class="divCenter">
                      <P>{{ value.couponName }}</P>
                      <p>{{ value.validDate }}-{{ value.invalidDate }}</p>
                      <p v-if="value.durationLimit">
                        使用时间段：{{ value.durationLimit }}
                      </p>
                    </div>
                    <div class="divRight">
                      <span class="moneySpan">
                        <span class="money1" v-if="value.type != 30">￥</span>
                        <span class="money2">{{
                          formatDiscount(value.type, value.discount)
                        }}</span>
                        <span class="money1" v-if="value.type == 30">折</span>
                      </span>
                      <!--                    <p class="p1" v-if="value.vip">会员专享</p>-->
                      <p class="p2" v-show="value.type == 10">
                        {{ fullTip(value.full) }}
                      </p>
                    </div>
                  </div>
                  <!--              虚线-->
                  <div>
                    <div class="leftCircle"></div>
                    <van-divider
                      dashed
                      class="dashLine"
                      :style="{ borderColor: '#0000001A' }"
                    >
                    </van-divider>
                    <!--                  <div class="dashLine"></div>-->
                    <div class="rightCircle"></div>
                  </div>
                  <!--                  使用规则-->
                  <div>
                    <div class="top">
                      <div
                        class="openRule"
                        @click="checkDescription($event, key)"
                      >
                        <span
                          class="spanRule"
                          :class="{ opacity: status == 2 || status == 4 }"
                          >使用规则</span
                        >
                        <img
                          class="arrowIcon"
                          v-if="listItem.includes(key)"
                          src="../../assets/icon_up_arrow.png"
                        />
                        <img
                          class="arrowIcon"
                          v-if="!listItem.includes(key)"
                          src="../../assets/icon_down_arrow.png"
                        />
                      </div>
                      <button
                        class="button"
                        :class="isVipBtn(value.vip)"
                        v-if="
                          value.kind == 20 ||
                          value.kind == 30 ||
                          value.kind == 40 ||
                          value.kind == 50 ||
                          value.kind == 80
                        "
                        @click="buttonOnClick(value.kind)"
                      >
                        去使用
                      </button>
                    </div>
                    <p class="bottom" :class="{ show: listItem.includes(key) }">{{ value.description }}</p>
                  </div>
                </div>
              </div>
              <div v-if="value.count > 1"></div>
              <div v-if="value.count > 1"></div>
              <div v-if="value.count > 1"></div>
            </div>
          </van-list>
          <!-- 没有数据 -->
          <div class="noData" v-else>
            <img src="../../assets/icon_no_coupon.png" alt="" />
            <p>无优惠券</p>
          </div>
        </van-pull-refresh>
      </div>
      <p class="history" @click="toHistory(kind)">历史记录</p>
    </div>
  </div>
</template>

<script>
import { kindCount, personalCoupon } from "../../api/HttpService";
import { globalConfig } from "../../common/js/config";

export default {
  name: "CouponListPage",
  created: function () {
    setTimeout(() => {
      this.navBottomLine.left =
        this.$refs.navList[globalConfig.couponIndex].offsetLeft + "px";
      this.navBottomLine.width =
        this.$refs.navList[globalConfig.couponIndex].offsetWidth + "px";
    }, 0);
  },
  mounted: function () {
    document.title = "优惠券";
    this.getKindCount();
    if (globalConfig.isHistory == 0) {
      if (this.$route.query.type) {
        switch (this.$route.query.type) {
          case "bus":
            globalConfig.couponIndex = 0;
            break;
          case "glx":
            globalConfig.couponIndex = 1;
            break;
          case "customBus":
            globalConfig.couponIndex = 2;
            break;
          case "taxi":
            globalConfig.couponIndex = 3;
            break;
          case "other":
            if (this.isFromWXMP) {
              globalConfig.couponIndex = 1;
            } else {
              globalConfig.couponIndex = 4;
            }
            break;
        }
      }
    }
    this.navList.nowSelectedNo = globalConfig.couponIndex;
    switch (globalConfig.couponIndex) {
      case 0:
        this.kind = 10;
        break;
      case 1:
        this.kind = 30;
        break;
      case 2:
        this.kind = 20;
        break;
      case 3:
        this.kind = 40;
        break;
      case 4:
        this.kind = -1;
        break;
    }
    if (this.isFromWXMP) {
      this.navList = {
        nowSelectedNo: 0,
        list: ["公交券","汽车票券", "其他"],
      };
      switch (globalConfig.couponIndex) {
        case 0:
          this.kind = 10;
          break;
        case 1:
          this.kind = 30;
          break;
        case 2:
          this.kind = -1;
          break;
      }
    }
    this.changeNav(globalConfig.couponIndex);
    // this.busList = [];
    // this.personalCoupon()
    window.localStorage.setItem("historyKind", "");
  },
  data: function () {
    return {
      navList: {
        nowSelectedNo: 0,
        list: ["公交券 0", "汽车票券 0", "智享公交券 0", "打车券 0", "其他 0"],
      },
      navBottomLine: {
        left: "0px",
        width: "0px",
      },
      loading: false,
      //   公交
      busList: [],
      refreshing: false,
      finished: false,
      pageNum: 1,
      pageSize: 8,
      kind: 10,
      total: 0,
      descriptionShow: false,
      arrow: 0,
      listItem: [],
      status: 1,
      isFromWXMP: localStorage.getItem("hostProgram") == "wxmp",
      index: 0, //tab的标志位
    };
  },

  methods: {
    //   点击顶部nav
    changeNav: async function (key) {
      this.index = key;
      if (this.isFromWXMP) {
        switch (key) {
          case 0:
            this.kind = 10;
            break;
          case 1:
            this.kind = 30;
            break;
          case 2:
            this.kind = -1;
            break;
        }
      } else {
        switch (key) {
          case 0:
            this.kind = 10;
            break;
          case 1:
            this.kind = 30;
            break;
          case 2:
            this.kind = 20;
            break;
          case 3:
            this.kind = 40;
            break;
          case 4:
            this.kind = -1;
            break;
        }
      }
      this.navList.nowSelectedNo = key;
      this.busList = [];
      this.listItem = [];
      this.pageNum = 1;
      this.finished = false;
      setTimeout(() => {
        this.navBottomLine.left =
          this.$refs.navList[this.index].offsetLeft + "px";
        this.navBottomLine.width =
          this.$refs.navList[this.index].offsetWidth + "px";
      }, 100);
      await this.personalCoupon();
    },
    checkDescription: async function ($event, key) {
      if (this.listItem.includes(key)) {
        var item = this.listItem.indexOf(key);
        this.listItem.splice(item, 1);
      } else {
        this.listItem.push(key);
      }
    },
    // 获取数据
    personalCoupon: async function () {
      let params = {
        pageNum: this.pageNum,
        pageSize: 10,
        kind: this.kind,
        status: 1,
        classes: 10,
      };
      await personalCoupon(params)
        .then(({ res }) => {
          this.loading = false;
          console.log(JSON.stringify(res.data));
          // 如果没有下一页了
          if (res.data.pageNum >= res.data.pages) {
            console.log("是否还有下一页");
            this.finished = true;
          } else {
            this.finished = false;
          }
          this.busList.push(...res.data.data);
          this.pageNum++;
        })
        .catch((err) => {
          console.log(err);
          // this.$toast("网络异常");
        });
    },

    getKindCount: async function () {
      let params = {};
      await kindCount(params)
          .then(({res}) => {
            console.log(JSON.stringify(res.data))
            for (let i in res.data) {
              if (res.data[i].kind == 10){
                this.navList.list.splice(0, 1, "公交券 "+(res.data[i].count > 99 ? '99+' : res.data[i].count));
              }
              if (res.data[i].kind == 30){
                this.navList.list.splice(1, 1, "汽车票券 "+(res.data[i].count > 99 ? '99+' : res.data[i].count));
              }
              if (res.data[i].kind == 20){
                this.navList.list.splice(2, 1, "智享公交券 "+(res.data[i].count > 99 ? '99+' : res.data[i].count));
              }
              if (res.data[i].kind == 40){
                this.navList.list.splice(3, 1, "打车券 "+(res.data[i].count > 99 ? '99+' : res.data[i].count));
              }
              if (res.data[i].kind == -1){
                if (this.isFromWXMP){
                  this.navList.list.splice(2, 1, "其他 "+(res.data[i].count > 99 ? '99+' : res.data[i].count));
                }else{
                  this.navList.list.splice(4, 1, "其他 "+(res.data[i].count > 99 ? '99+' : res.data[i].count));
                }
              }

            }

          })
          .catch((err) => {
            console.log(err);
            // this.$toast("网络异常");
          });
    },

    onLoad: async function () {
      console.log("加载下一页");
      await this.personalCoupon();
    },
    // list刷新
    onRefresh: async function () {
      console.log("开始刷新");
      this.busList = [];
      this.pageNum = 1;
      this.finished = false;
      this.refreshing = true;
      await this.personalCoupon();
      this.refreshing = false;
    },
    //历史优惠券呢
    toHistory: function (kind) {
      globalConfig.couponIndex = this.navList.nowSelectedNo;
      globalConfig.isHistory = 1;
      this.$router.push({
        name: "HistoryListPage",
        params: {
          kind: kind,
        },
      });
    },
    //右侧button的点击事件
    buttonOnClick: function (kind) {
      switch (kind) {
        case 20: //定制公交订单
          window.location.href = "liangla://main/custom_bus";
          break;
        case 30: //国联行
          var _url =
            window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/glx/#/"
              : "https://lzgj.test.brightcns.cn/glx/#/";
          window.location.href = _url;
          break;
        case 40: //悦行
          // this.$toast("跳转悦行");
          var url =
            window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/take-taxi/#/"
              : "https://lzgj.test.brightcns.cn/take-taxi/#/";
          window.location.href = url;
          break;
        case 50: //权益中心
          var url_ =
            window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/benefits/#/OpenVipPage?fromOutside=true"
              : "https://lzgj.test.brightcns.cn/benefits/#/OpenVipPage?fromOutside=true";
          window.location.href = url_;
          break;
        case 80: //话费充值
          var url_ =
              window.location.host.match(/test/) == null
                  ? "https://h5-lzgj.brightcns.com/prepaid-recharge/#/IndexPage"
                  : "https://lzgj.test.brightcns.cn/prepaid-recharge/#/IndexPage";
          window.location.href = url_;
          break;
      }
    },
    //折扣赋值转换
    formatDiscount: function (type, discount) {
      switch (type) {
        case 30:
          return this.formatMoney(discount * 10);
        default:
          return discount;
      }
    },
    //格式化
    formatMoney: function (money) {
      var value = Math.round(parseFloat(money) * 100) / 100;
      var xsd = value.toString().split(".");
      if (xsd.length == 1) {
        value = value.toString() + ".0";
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 1) {
          value = value.toString() + "0";
        }
        return value;
      }
      return value;
    },

    fullTip: function (full) {
      return "满" + full / 100 + "元可用";
    },
    isVipStyle: function (isVip, count) {
      console.log("===" + count);
      if (isVip && count > 1) {
        return "vipMoreBg";
      }
      if (isVip) {
        return "vipBg";
      }
    },
    isVipBtn: function (isVip) {
      if (isVip) {
        return "vipBtn";
      }
      return "Btn";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/scss/coupon.scss";
@import "../../common/scss/nav.scss";
@import "../../page/CouponListPage/CouponListPage.scss";
</style>

<template>
  <div class="cardRule">
    <div class="div1">道行龙城 柳州公交折扣卡使用规则</div>
    <div class="div2">
      <p class="p1">
        1.
      </p>
      <p class="p2">
        本卡仅支持通过道行龙城微信小程序乘车码乘车使用，卡内包含的乘车优惠/有效期/每日限乘次数/单次最高优惠/最多可享优惠次数，以"购卡页&我的卡券"内展示为准；
      </p>
    </div>
    <div class="div2">
      <p class="p1">
        2.
      </p>
      <p class="p2">
        购买成功即可使用折扣卡优惠，按乘车实际费用进行折扣优惠（例：乘坐2元票款的公交车，享受5折优惠后，用户需支付1元。）；
      </p>
    </div>
    <div class="div2">
      <p class="p1">
        3.
      </p>
      <p class="p2">
        有效期自购卡日当日起计算至有效截止时间当日23:59，一经售出不支持退款，逾期未使用将自动作废；
      </p>
    </div>
    <div class="div2">
      <p class="p1">
        4.
      </p>
      <p class="p2">
        活动使用范围：恒达巴士运营的柳州市内公交线路（智享公交除外）。
      </p>
    </div>
    <div class="div2">
      <p class="p1">
        5.
      </p>
      <p class="p2">
        用户可至“道行龙城微信小程序-我的卡券”中查看有效期以及使用记录；
      </p>
    </div>
    <div class="div2">
      <p class="p1">
        6.
      </p>
      <p class="p2">
        如已享受优惠的乘车交易发生退款，仅退还用户实际支付的金额（不含折扣优惠金额），已享受优惠资格不再补偿；
      </p>
    </div>
    <div class="div2">
      <p class="p1">
        7.
      </p>
      <p class="p2">
        该卡在进行优惠后需付金额≥1元时，将与其他支付侧优惠券叠加使用（注：折扣卡和优惠券不可叠加）；
      </p>
    </div>
    <div class="div2">
      <p class="p1">
        8.
      </p>
      <p class="p2">
        优惠不可拆分、不可兑换现金、提现、转赠、不可找零。
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "cardRule",
  mounted: async function () {
    document.title = "使用规则";
  },
}
</script>

<style lang="scss" scoped>
@import "../../page/CardRulePage/CardRulePage";
</style>

import axios from 'axios'
import {Notify} from 'vant'
import {globalConfig} from "../common/js/config";

axios.defaults.timeout = 10000
// response拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.code == 'TOKEN_CHECK_OVERDUE' || response.data.code == 'TOKEN_CHECK_NO_AUTHORITY' || response.data.code == 'AUTH_API_NO_AUTHORITY') {
            // 清空登录记录
            localStorage.removeItem('sessionId')
            // 统一跳到 oauth页面
            var _url =
                window.location.host.match(/test/) == null
                    ? "https://h5-lzgj.brightcns.com/dxlc-discount/#/OauthPage"
                    : "https://lzgj.test.brightcns.cn/dxlc-discount/#/OauthPage";
            if (globalConfig.isFromBus){
                _url =
                    window.location.host.match(/test/) == null
                        ? "https://h5-lzgj.brightcns.com/dxlc-discount/#/OauthPage?from=bus"
                        : "https://lzgj.test.brightcns.cn/dxlc-discount/#/OauthPage?from=bus";
            }
            Notify({ type: 'danger', message: '登录超时！' });
            setTimeout(() => {
                window.location.replace(_url);
            }, 1000)
        } else {
            return response
        }
    },
    error => {
        return Promise.reject(error)
    }
)

const handleStatus = (result) => {
    console.log("请求结果：" + JSON.stringify(result))
    if (result.status === 200) {
        const a = {}
        a.code = 'SUCCESS'
        if (result.data.bizResp) {
            // var JSONbigString = require('json-bigint')({"storeAsString": true});
            // a.data = JSONbigString.parse(result.data.bizResp)
            a.data = JSON.parse(result.data.bizResp)
        }
        a.originalData = {}
        for (const i in result.data) {
            if (i == 'bizResp') {
                a.originalData[i] = JSON.parse(result.data[i])
            } else {
                a.originalData[i] = result.data[i]
            }
        }
        return {res: a, headers: result.headers}
    } else if (result.status === 500) {
        const a = JSON.parse(result.data.bizResp)
        a.code = 'SUCCESS'
        return {res: a, headers: result.headers}
    } else {
        // switch (result.status) {
        // }
        this.$toast("网络异常");
        return Promise.reject(result)
    }
}

const handleStatus1 = (result) => {
    console.log("请求结果：" + JSON.stringify(result.data.bizResp))
    if (result.status === 200) {
        const a = {}
        a.code = 'SUCCESS'
        if (result.data.bizResp) {
            var JSONbigString = require('json-bigint')({"storeAsString": true});
            a.data = JSONbigString.parse(result.data.bizResp)
            // a.data = JSON.parse(result.data.bizResp)
        }
        a.originalData = {}
        for (const i in result.data) {
            if (i == 'bizResp') {
                a.originalData[i] = JSON.parse(result.data[i])
            } else {
                a.originalData[i] = result.data[i]
            }
        }
        return {res: a, headers: result.headers}
    } else if (result.status === 500) {
        const a = JSON.parse(result.data.bizResp)
        a.code = 'SUCCESS'
        return {res: a, headers: result.headers}
    } else {
        // switch (result.status) {
        // }
        return Promise.reject(result)
    }
}

export default {
    post(url, params) {
        console.info('post请求地址：' + url)
        console.info('post请求地址：' + JSON.stringify(params))
        return axios
            .post(url, JSON.stringify(params), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(handleStatus)
            .catch(error => {
                return Promise.resolve(error.data)
            })
    },
    post1(url, params) {
        console.info('post请求地址：' + url)
        console.info('post请求地址：' + JSON.stringify(params))
        return axios
            .post(url, JSON.stringify(params), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(handleStatus1)
            .catch(error => {
                return Promise.resolve(error.data)
            })
    }

}


import Vue from "vue";
import VueRouter from "vue-router";
// 注入插件
Vue.use(VueRouter)

import BusCouponListPage from '../page/BusCouponListPage/BusCouponListPage'
import cardDetail from '../page/cardDetail/cardDetail'
import DiscountHomePage from '../page/DiscountHomePage/DiscountHomePage'
import CardRulePage from '../page/CardRulePage/CardRulePage'
import orderList from '../page/orderList/orderList'
import orderDetail from '../page/orderDetail/orderDetail'
import CouponListPage from "../page/CouponListPage/CouponListPage";
import HistoryListPage from "../page/HistoryListPage/HistoryListPage";
import OauthPage from "../page/OauthPage/OauthPage";
import WechatProgramLoginPage from "../page/WechatProgramLoginPage/WechatProgramLoginPage";


// 定义路由
const routes = [
    //添加映射关系
    {
        path: '/',
        name: 'OauthPage',
        component: OauthPage
    },
    {
        path: '/OauthPage',
        name: 'OauthPage',
        component: OauthPage
    },
    {
        path: '/DiscountHomePage',
        name: 'DiscountHomePage',
        component: DiscountHomePage
    },
    {
        path: '/BusCouponListPage',
        name: 'BusCouponListPage',
        component: BusCouponListPage
    },
    {
        path: '/cardDetail',
        name: 'cardDetail',
        component: cardDetail
    },
    {
        path: '/CardRulePage',
        name:'CardRulePage',
        component: CardRulePage
    },
    {
        path: '/orderList',
        name:'orderList',
        component: orderList
    },
    {
        path: '/orderDetail',
        name:'orderDetail',
        component: orderDetail
    },
    {
        path: '/CouponListPage',
        name:'CouponListPage',
        component: CouponListPage
    },
    {
        path: '/HistoryListPage',
        name:'HistoryListPage',
        component: HistoryListPage
    },
    {
        path: '/WechatProgramLoginPage',
        name: 'WechatProgramLoginPage',
        component: WechatProgramLoginPage
    },
    {
        path: '*',
        component: OauthPage
    },

]

// 创建实例
const router = new VueRouter({
     routes
})

// 导出实例
export default router

<template>
  <div class="discountHome" v-if="data">
    <!--    选择优惠方式-->
    <div class="discountType" @click=onShowDialog>
      <p class="p1">{{isFromRide ? "选择优先优惠方式" : "公交优先优惠方式"}}</p>
      <p class="p2">{{ defaultPay() }}</p>
      <img class="icon" src="../../assets/icon_right_arrow.png">
    </div>
    <!--    可用优惠列表-->
    <van-list>
      <div v-for="item in data" :key="item.index" :title="item" @click="jumpPage(item.type)">
        <div class="listItem">
          <img class="itemIcon" :src="iconSet(item)">
          <p class="itemType">{{ item.name }}</p>
          <p class="itemInfo" :class="infoSet(item).class">{{ infoSet(item).info }}</p>
          <img class="icon" src="../../assets/icon_right_arrow.png">
        </div>
      </div>
    </van-list>
    <!--    使用规则-->
    <p class="rule-description" v-if="isFromRide">优惠说明</p>
    <div class="rule" v-if="isFromRide">
      <p class="rule-content">1.</p>
      <p class="rule-content">优惠券或折扣卡，不可叠加或同时使用。选择的优先优惠方式如已用尽，自动切换其他可用优惠。</p>
    </div>
    <div class="rule" v-if="isFromRide &&!isFromWXMP">
      <p class="rule-content">2.</p>
      <p class="rule-content"><span>优惠方式（优惠券/折扣卡）优先于扣款方式，扣款的优惠需满足活动方的条件，</span><span class="rule-info"
                                                                                     @click="jumpActivityInfo()">参考具体活动信息。</span>
      </p>
    </div>
    <div class="rule" v-if="isFromRide && isFromWXMP">
      <p class="rule-content">2.</p>
      <p class="rule-content">优惠方式（优惠券/折扣卡）优先于扣款方式的支付侧优惠，扣款的优惠需满足活动方的条件。
      </p>
    </div>
    <div class="rule" v-if="isFromRide">
      <p class="rule-content">3.</p>
      <p class="rule-content">乘车订单以系统扣款时用户设定的优惠方式和扣款方式为准。</p>
    </div>
    <div class="rule" v-if="isFromRide">
      <p class="rule-content">4.</p>
      <p class="rule-content">如用户因支付账户状态问题（包括但不限于卡余额不足、卡冻结、卡销户等状态异常）造成首次扣款失败，后续补缴时按原价支付。</p>
    </div>

    <!--    使用规则-->
    <p class="rule-description" v-if="!isFromWXMP">会员优惠券说明</p>
    <div class="rule" v-if="!isFromWXMP">
      <p class="rule-content">1.</p>
      <p class="rule-content">会员优惠券有效期与会员有效期一致，均为31天，请及时使用。</p>
    </div>
    <div class="rule" v-if="!isFromWXMP">
      <p class="rule-content">2.</p>
      <p class="rule-content">提前续费会员后，会员优惠券将于下一会员周期发放，具体发放时间可在会员详情页面查看。</p>
    </div>

    <!-- 弹出框 -->
    <div class="dialog" v-show="show" @click="show = false">
      <transition name="fullscreen">
        <div
            v-show="show" @click="show = false"
            class="dialogContent"
            :class="{ dialogContentActive: show }">
          <div v-for="(item, index) in actions" :key="index">
            <div class="item" @click="onSelect(item)">{{ item.name }}</div>
          </div>
          <div class="cancelBtn" @click="show = false">取消</div>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
import {Toast} from "vant";
import {benefitDefault, benefitItem, benefitQuery} from "../../api/HttpService";
import couponIcon from "../../assets/icon_coupons.png"
import cardIcon from "../../assets/icon_card.png"
import {globalConfig} from "../../common/js/config";


export default {
  name: 'DiscountHomePage',
  mounted: function () {
    globalConfig.couponIndex = 0
    if (this.getParams("from") == "bus") {
      this.isFromRide = true
    }
    globalConfig.isFromBus = this.isFromRide
    console.log("来源" + this.getParams("from"))
    this.benefitQuery()
    this.benefitItem()
    document.title = "我的卡券";
  },
  data() {
    return {
      show: false,
      isFromRide: false,
      data: [],
      item: [],
      actions: [],
      cardNumType: '',
      isFromWXMP: localStorage.getItem("hostProgram") == "wxmp",
    };
  },
  methods: {
    getParams: function (name) {
      var search = window.location.search.substring(1)
      if (!search) {
        search = window.location.hash.split('?')[1]
      }
      if (search) {
        var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        return name ? obj[name] : obj
      }
    },
    // 获取数据
    benefitQuery: async function () {
      let params
      if (this.isFromRide && !this.isFromWXMP) {
        params = {
          kind: 10,
        };
      } else {
        params = {};
      }
      await benefitQuery(params)
          .then(({res}) => {
            // console.log("获取结果是" + JSON.stringify(res.data));
            this.data = res.data
          })
          .catch((err) => {
            console.log(err);
            this.$toast("网络异常");
          });
    },
    //bottom列表选项
    benefitItem: async function () {
      let params = {};
      await benefitItem(params)
          .then(({res}) => {
            for (let i in res.data) {
              console.log("benefitItem获取结果是" + JSON.stringify(res.data[i]));
              this.actions.push({name: res.data[i].info, type: res.data[i].type, info: res.data[i].name})
            }
            // this.actions = res.data
          })
          .catch((err) => {
            console.log(err);
            this.$toast("网络异常");
            // return false;
          });
      // return result;
    },
    //设置默认优惠方式
    benefitDefault: async function (type) {
      let params = {
        type: type,
      };
      await benefitDefault(params)
          .then(({res}) => {
            console.log("benefitDefault获取结果是" + JSON.stringify(res.data));
            // this.actions = res.data
            Toast('设置成功');
            this.benefitQuery()
          })
          .catch((err) => {
            console.log(err);
            this.$toast("网络异常");
            // return false;
          });
      // return result;
    },
    onCancel() {
      // Toast('取消');
    },
    onShowDialog() {
      this.show = true
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.benefitDefault(item.type)
      // Toast(item.type);
    },
    //  优惠栏默认显示
    defaultPay: function () {
      if (this.data == null || this.data[0] == null) {
        return ""
      }
      if (this.data[0].use == "1") {
        return this.data[0].name
      }
      return "不使用优惠券/折扣卡"
    },
    //icon 图片显示
    iconSet: function (item) {
      if (item.type == "ticket") {
        return couponIcon
      }
      return cardIcon
    },
    //卡券info显示
    infoSet: function (item) {
      if (item.type == "ticket") {
        if (item.detail.count > 0) {
          return {
            info: item.detail.count + "张可用",
            class: "orange"
          }
        }
        return {
          info: item.detail.count + "张可用",
          class: "gray"
        }
      } else {
        if (item.detail.count > 0) {
          return {
            info: item.detail.name,
            class: "orange"
          }
        }
        return {
          info: "无正在使用折扣卡",
          class: "gray"
        }
      }
    },
    jumpActivityInfo: function () {
      var _url =
          window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/service-center/#/PayQuestion_4"
              : "https://lzgj.test.brightcns.cn/service-center/#/PayQuestion_4";
      window.location.href = _url;
    },
    // 跳转到对应优惠页
    jumpPage: function (type) {
      if (type == "card") {
        this.$router.push({
          name: "cardDetail",
        });
        return
      }
      if (this.isFromRide && !this.isFromWXMP) {
        this.$router.push({
          name: "BusCouponListPage",
        });
        return;
      }
      this.$router.push({
        name: "CouponListPage",
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../page/DiscountHomePage/DiscountHomePage.scss";
</style>

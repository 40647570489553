// 定义项目全局配置
const globalConfig = {
    couponIndex: 0,//默认为0
    isHistory: 0,//默认为0 是切换到历史的tab
    isFromBus: false,//默认为false 不是乘车页跳入
};

export {
    globalConfig
};

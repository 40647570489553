<template>
  <div class="orderDetail">
    <!--    上半部分-->
    <div class="detailDiv">
      <div class="divContent">
        <img class="detailImg" v-bind:src="data.icon">
        <div class="divDetail">
          <p class="detailP1"> {{ data.goodsName }}</p>
          <div class="detailGoodsDiv">
            <p class="detailS1" v-if="goodsDetail">附赠</p>
            <div class="detailSDiv">
              <p class="detailS2">{{goodsDetail}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      下半部分-->
    <div class="bottomDiv">
      <span class="bottomS1">订单金额</span>
      <span class="bottomMoney">{{ formatMoney(orderPrice) }}</span>
    </div>
    <p class="parLine"></p>
    <div class="bottomDiv">
      <span class="bottomS1">订单编号</span>
      <span class="bottomS2">{{data.orderNo}}</span>
    </div>
    <div class="bottomDiv">
      <span class="bottomS1">下单日期</span>
      <span class="bottomS2">{{ formatTime(data.updateTime) }}</span>
    </div>
    <div class="bottomDiv">
      <span class="bottomS1">支付方式</span>
      <span class="bottomS2">{{ formatPayWay(payWay) }}</span>
    </div>
  </div>
</template>

<script>
import {orderDetail} from "../../api/HttpService";
import moment from "moment";

export default {
  name: "orderDetail",
  mounted: async function () {
    this.orderNo = this.$route.params.orderNo;
    this.payWay = this.$route.params.payWay;
    this.orderPrice = this.$route.params.orderPrice;
    await this.getData()
    document.title = "订单详情";
  },
  data: function () {
    return {
      orderNo:'',
      loading: false,
      list:[],
      data: null,
      payWay: null,
      orderPrice: null,
      goodsDetail:''
    };
  },
  methods: {
    // 获取数据
    getData: async function () {
      let params = {
        orderNo:this.orderNo
      };
      await orderDetail(params)
          .then(({res}) => {
            console.log("获取结果是" + JSON.stringify(res.data));
            this.forGoods(res.data)
            // this.data = res.data[0]
          })
          .catch((err) => {
            console.log(err);
            this.$toast("网络异常");
          });
    },

    //
    formatPayWay: function (payWay) {
      if (payWay == 'wechat'){
        return '微信支付'
      }
      return '支付宝支付'
    },

    //获取获赠
    forGoods: function (list){
      for (let i in list) {
        if (list[i].bigGoodsType == "card"){
          this.data = list[i]
        }else{
          this.goodsDetail = this.goodsDetail+list[i].goodsName+" "+list[i].goodsNumber+"张"+" "
          console.log(this.goodsDetail)
        }
      }
    },

    //格式化金额
    formatMoney: function (money) {
      var value = Math.round(parseFloat(money)) / 100;
      var xsd = value.toString().split(".");
      if (xsd.length == 1) {
        value = value.toString() + ".00";
        return value+'元';
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + "0";
        }
        return value+'元';
      }
      return value+'元';
    },
    //格式化时间
    formatTime: function (time){
      moment.locale('zh-cn');
      return moment(time).format("yyyy-MM-DD HH:mm:ss");
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../../page/orderDetail/orderDetail.scss";
</style>
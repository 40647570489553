<template>
    <div class="busCouponList">
      <!-- 列表 -->
      <div class="vanCouponList">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="van-pull-refresh">
          <van-list
              class="dataList"
              v-model="loading"
              :finished="finished"
              @load="onLoad"
              v-if="busList.length > 0"
              offset="300"
          >
            <div
                class="itemBox"
                v-for="(value, key) in busList"
                :key="key"
            >
              <div class="dataItem" :class="isVipStyle(false,value.count)">
                <p class="vipTag"> <span>{{value.activityName}} </span> <img src="../../assets/icon_cheng.png"> <span>{{value.count}}张</span></p>
              <div class="dataItemBox" >
                <!--              优惠券信息-->
                <div>
<!--                  <img-->
<!--                      class="icon" v-bind:src="value.icon">-->
                  <div class="divCenter">
                    <P>{{ value.couponName }}</P>
                    <p>{{ value.validDate }}-{{ value.invalidDate }}</p>
                  </div>
                  <div class="divRight">
                    <span class="moneySpan">
                      <span class="money1" v-if="value.type!=30">￥</span>
                      <span class="money2">{{ formatDiscount( value.type,value.discount) }}</span>
                      <span class="money1" v-if="value.type==30">折</span>
                    </span>
<!--                    <p class="p1" v-if="value.vip">会员专享</p>-->
                    <p class="p2" v-show="value.type==10">{{ fullTip(value.full) }}</p>
                  </div>
                </div>
                <!--              虚线-->
                <div>
                  <div class="leftCircle">
                  </div>
                  <van-divider dashed class="dashLine"
                      :style="{  borderColor: '#0000001A' }">
                  </van-divider>
<!--                  <div class="dashLine"></div>-->
                  <div class="rightCircle">

                  </div>
                </div>
                <!--                  使用规则-->
                <div>
                  <div class="top">
                    <div class="openRule" @click="checkDescription($event, key)">
                      <span class="spanRule" :class="{ opacity: status==2 ||status==4 }">使用规则</span>
                      <img class="arrowIcon" v-if="listItem.includes(key)"
                           src="../../assets/icon_up_arrow.png">
                      <img class="arrowIcon" v-if="!listItem.includes(key)"
                           src="../../assets/icon_down_arrow.png">
                    </div>
                    <button class="button" :class="isVipBtn(value.vip)" v-if="status==2">去使用</button>
                  </div>
                  <p class="bottom" :class="{ show: listItem.includes(key)  }">{{ value.description }}</p>
                </div>
              </div>
              </div>
              <div v-if="value.count>1"></div>
              <div v-if="value.count>1"></div>
              <div v-if="value.count>1"></div>
            </div>
          </van-list>
          <!-- 没有数据 -->
          <div class="noData" v-else>
            <img
                src="../../assets/icon_no_coupon.png"
                alt=""
            />
            <p>无优惠券</p>
          </div>
        </van-pull-refresh>
      </div>
      <p class="history" @click="toHistory()">历史记录</p>
    </div>
</template>

<script>
import {personalCoupon} from "../../api/HttpService";

export default {
  name: "busCouponList",
  mounted: function () {
    document.title = "优惠券";
    this.personalCoupon()
    window.localStorage.setItem("historyKind",'')
  },
  data: function () {
    return {
      loading: false,
      //   公交
      busList: [],
      discountList:[],
      refreshing: false,
      finished: false,
      pageNum: 1,
      pageSize: 8,
      total: 0,
      listItem: [],
      status:1,

    };
  },
  methods: {
    // 获取数据
    personalCoupon: async function () {
      let params = {
        pageNum:this.pageNum,
        pageSize:10,
        kind:10,
        status:1,
        classes: 10,
      };
      await personalCoupon(params)
          .then(({res}) => {
            this.loading = false;
            // 如果没有下一页了
            if (res.data.pageNum >= res.data.pages) {
              console.log("是否还有下一页")
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.busList.push(...res.data.data)
            this.pageNum++;
          })
          .catch((err) => {
            console.log(err);
            // this.$toast("网络异常");
          });
    },
    onLoad: async function () {
      console.log("加载下一页");
      await this.personalCoupon();
    },
    // list刷新
    onRefresh: async function () {
      console.log("开始刷新");
      this.busList = [];
      this.pageNum = 1;
      this.finished = false;
      this.refreshing = true;
      await this.personalCoupon();
      this.refreshing = false;
    },
    checkDescription: async function ($event, key) {
      if (this.listItem.includes(key)) {
        console.log(event.target +key+ "splice" + this.listItem)
        var item = this.listItem.indexOf(key)
        this.listItem.splice(item, 1)
      } else {
        this.listItem.push(key);
      }
    },
    //历史优惠券呢
    toHistory: function () {
      this.$router.push({
        name: "HistoryListPage",
        params: {
          kind: 10,
        },
      });
    },
    //折扣赋值转换
    formatDiscount:function (type,discount){
      switch (type) {
        case 30:
          return this.formatMoney(discount*10);
        default:
          return discount;
      }
    },
    //格式化
    formatMoney: function (money) {
      var value = Math.round(parseFloat(money) * 100) / 100;
      var xsd = value.toString().split(".");
      if (xsd.length == 1) {
        value = value.toString() + ".0";
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 1) {
          value = value.toString() + "0";
        }
        return value;
      }
      return value;
    },

    fullTip: function(full){
      return "满"+full/100+"元可用"
    },

    isVipStyle:function (isVip,count) {
      console.log("==="+count)
      if (isVip && count > 1){
        return "vipMoreBg"
      }
      if (isVip){
        return "vipBg"
      }
    },
    isVipBtn:function (isVip) {
      if (isVip){
        return "vipBtn"
      }
      return "Btn"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../common/scss/coupon.scss";
@import "BusCouponListPage";

</style>

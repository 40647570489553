
const API = {
  // 查询生效卡
  validCard: 'apps.coupon.validCard',
  // 我的卡券列表
  benefitQuery: 'apps.user.benefit.query',

  benefitItem: 'apps.user.benefit.item',

  benefitDefault: 'apps.user.benefit.default',

  personalCoupon: 'apps.coupon.personal',

  orderQuery: 'activity.order.query',

  orderDetail: 'activity.order.detail',

  kindCount: 'apps.coupon.kind.count',

  busOrderList: 'apps.order.orders',

  busOrderDetail: 'apps.order.order.detail',

  deductOrder: 'apps.order.run.deductOrder',

  // 通过小程序sessionId换取sessionId
  loginS2S: "apps.user.login.s2s",


}
export {
  API
}


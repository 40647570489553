<template>
  <div class="orderListPage" :class="changeBg(navActiveIndex).class">
    <div class="navBar">
      <div class="orderNavItem" @click="changeNavIndex(0)">
        <span>订单</span>
        <div class="orderNavBottomLine" v-show="navActiveIndex == 0"></div>
      </div>
      <div class="orderNavItem" @click="changeNavIndex(1)">
        <span>折扣卡</span>
        <div class="orderNavBottomLine" v-show="navActiveIndex == 1"></div>
      </div>
    </div>

    <!-- 两个列表 -->
    <div class="orderListContent">
      <!-- 订单列表 -->
      <div class="orderList" v-show="navActiveIndex == 0">
        <van-pull-refresh class="busList" v-model="orderRefreshing" @refresh="orderOnRefresh">
          <van-list
              v-model="orderListLoading"
              :finished="orderFinished"
              @load="orderOnload"
              v-if="orderList.length > 0"
              offset="100">
            <div class="busListContent">
              <div
                  class="orderItem"
                  v-for="(value, key) in orderList"
                  :key="key"
                  @click="jumpOrderDetailPage(value.orderNo,value.payChnl,value.payAmount)">
                <div class="orderContentItem">
                  <img class="orderImg" :src="setIcon(value.orderType)"/>
                  <div class="div">
                    <div class="div1">
                      <span class="s1">{{ value.activityName }}</span>
                      <span class="money">{{ formatMoney(value.payAmount) }}</span>
                    </div>
                    <div class="div1">
                      <span class="time">{{ value.createTime }}</span>
                      <span class="time">{{ formatPayWay(value.payChnl) }}</span>
                    </div>
                  </div>
                </div>
                <p class="parLine"></p>
              </div>
            </div>
          </van-list>
          <!--           没有数据-->
          <div class="noData" v-else>
            <img
                src="https://bucket-sz-test-wqth5.oss-cn-shenzhen.aliyuncs.com/wqt-order/noDataIcon.png"
                alt=""
            />
            <div class="p1">暂无订单</div>
          </div>
        </van-pull-refresh>
      </div>


      <!-- 折扣卡列表 -->
      <div class="orderList" v-show="navActiveIndex == 1">
        <van-pull-refresh class="busList" v-model="cardRefreshing" @refresh="cardOnRefresh">
          <van-list
              v-model="cardListLoading"
              :finished="cardFinished"
              @load="cardOnLoad"
              v-if="cardList.length>0"
              offset="100">
            <div class="busListContent">
              <div
                  class="cardItem"
                  v-for="(value, key) in cardList"
                  :key="key" @click="jumpPage(value.recordNo)">
                <div class="contentItem">
                  <img class="img" :src="setCardIcon(value.classes)">
                  <div class="div">
                    <div class="div1">
                      <span class="s1">{{ value.couponName }}</span>
                      <span class="s2" :class="formatCardType(value.status).class">{{ formatCardType(value.status).info }}</span>
                    </div>
                    <p class="time">{{ formatValidDate(value.validDate, value.invalidDate) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <!-- 没有数据 -->
          <div class="noData" v-else>
            <img
                src="../../assets/icon_no_card.png"
                alt=""
            />
            <div class="p1">暂未购买折扣卡</div>
          </div>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import {orderQuery, personalCoupon} from "../../api/HttpService";
import moment from "moment";
import iconW from "../../assets/icon_order_w.png"
import iconM from "../../assets/icon_order_m.png"
import iconS from "../../assets/icon_order_s.png"
import iconY from "../../assets/icon_order_y.png"
import cardW from "../../assets/icon_card_w.png"
import cardM from "../../assets/icon_card_m.png"
import cardS from "../../assets/icon_card_s.png"
import cardY from "../../assets/icon_card_y.png"
export default {
  name: "orderList",
  data: function () {
    return {
      activeNames: ['1'],
      active: 2,
      navActiveIndex: 0,
      loading: false,
      //
      orderList: [],
      orderRefreshing: false,
      orderListLoading: false,
      orderFinished: false,
      orderPageNum: 1,
      orderPageSize: 20,
      orderTotal: 0,

      //
      cardList: [],
      cardListLoading: false,
      cardFinished: false,
      cardRefreshing: false,
      cardPageNum: 1,
      cardPageSize: 20,
      cardTotal: 0,
    }
  },
  methods: {
    changeNavIndex: async function (index) {
      this.navActiveIndex = index
      console.log(index)
      this.changeBg(index)
    },
    changeBg:function (index){
      if (index == 1){
        return {class:"gray"}
      }
      return {class:"white"}

    },
    // 获取折扣卡订单数据
    orderQuery: async function () {
      let params = {
        pageNum: this.orderPageNum,
        pageSize: this.orderPageSize,
        orderStatus: 'SUCCESS',
      };
      await orderQuery(params)
          .then(({res}) => {
            // console.log("获取结果是" + res.data.data[0].orderNo);
            // this.data = res.data
            // this.orderList = res.data.data
            this.orderListLoading = false
            if (res.data.pageNum == res.data.pages) {
              console.log("是否还有下一页")
              this.orderFinished = true;
            } else {
              this.orderFinished = false;
            }
            this.orderList.push(...res.data.data)
            this.orderPageNum++;

          })
          .catch((err) => {
            console.log(err);
            // this.$toast("网络异常");
            // return false;
          });
      // return result;
    },
    // 获取折扣卡数据
    cardQuery: async function () {
      let params = {
        pageNum: this.cardPageNum,
        pageSize: this.cardPageSize,
        kind: 10,
        classes: 20,
      };
      await personalCoupon(params)
          .then(({res}) => {
            // console.log("获取结果是" + res.data.data[0].orderNo);
            // this.cardList = res.data.data
            this.cardListLoading = false
            if (res.data.pageNum == res.data.pages) {
              console.log("是否还有下一页")
              this.cardFinished = true;
            } else {
              this.cardFinished = false;
            }
            this.cardList.push(...res.data.data)
            this.cardPageNum++;
          })
          .catch((err) => {
            console.log(err);
            // this.$toast("网络异常");
            // return false;
          });
      // return result;
    },
    // list加载
    cardOnLoad: async function () {
      await this.cardQuery();
    },
    // list刷新
    cardOnRefresh: async function () {
      console.log("开始刷新");
      this.cardList = [];
      this.cardPageNum = 1;
      this.cardFinished = false;
      this.cardRefreshing = true;
      await this.cardQuery();
      this.cardRefreshing = false;
    },
    // 加载
    orderOnload: async function () {
      await this.orderQuery();
    },
    // 刷新
    orderOnRefresh: async function () {
      this.orderList = [];
      this.orderPageNum = 1;
      this.orderFinished = false;
      this.orderRefreshing = true;
      await this.orderQuery();
      this.orderRefreshing = false;
    },

    // 跳转到对应优惠页
    jumpPage: function (recordNo) {
      var _url =
          window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/dxlcbill/#/BillListPage?recordNo="+recordNo
              : "https://lzgj.test.brightcns.cn/dxlcbill/#/BillListPage?recordNo="+recordNo;
      console.log(window.location.host)
      window.location.href = _url;
    },

    //格式化有效期
    formatValidDate: function (invalidDate, grantTime) {
      moment.locale('zh-cn');
      return  moment(invalidDate).format('l').replaceAll('/','.') + "- " + moment(grantTime).format('l').replaceAll('/','.')
    },

    //格式化金额
    formatMoney: function (money) {
      var value = Math.round(parseFloat(money)) / 100;
      var xsd = value.toString().split(".");
      if (xsd.length == 1) {
        value = value.toString() + ".00";
        return '¥'+value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + "0";
        }
        return '¥'+value;
      }
      return '¥'+value;
    },

    //卡状态
    formatCardType: function (status) {
      switch (status) {
        case 1:
          return {info:'使用中',class:'orange'}
        case 2:
          return {info:'已使用',class:'darkGray'}
        case 4:
          return {info:'已过期',class:'darkGray'}
      }
    },

    //格式化支付方式描述
    formatPayWay: function (payWay) {
      if (payWay == 'wechat'){
        return '微信支付'
      }
      return '支付宝支付'
    },

    //页面背景色
    formatBackColor: function(){
      if (this.navActiveIndex == 1){
        return "F5"
      }
    },

    //订单icon
    setIcon: function (type){
      switch (type) {
        case 'week':
          return iconW
        case 'month':
          return iconM
      }
    },
    //折扣卡icon
    setCardIcon: function (type){
      switch (type) {
        case 21:
          return cardW
        case 22:
          return cardM
        case 23:
          return cardS
        case 24:
          return cardY
      }
    },

    // 跳转到账单详情
    jumpOrderDetailPage: function (orderNo,payWay,orderPrice) {
      this.$router.push({
        name: "orderDetail",
        params: {
          orderNo: orderNo,
          payWay: payWay,
          orderPrice: orderPrice,
        },
      });
    },

  },

  mounted: async function () {
    await this.orderQuery();
    await this.cardQuery();
    document.title = "已购";
  },
}
</script>

<style lang="scss" scoped>
@import "../../page/orderList/orderList.scss";
</style>
<template>
  <div class="cardDetail">
    <!--    卡面-->
    <div v-if="cardDetail">
      <van-image class="cardBg" fit="contain"
                 v-bind:src="cardDetail.icon"/>
      <van-image class="cardImg" fit="contain" radius="10"
                 v-bind:src="cardDetail.icon"/>
    </div>
    <!--    卡详情-->
    <div class="content" v-if="cardDetail">

        <div class="card1">
          <div class="div1">
            <p class="p1">{{ cardDetail.couponName }}</p>
            <p class="p2" @click = jumpCardRulePage()>使用规则</p>
            <img class="arrow" @click = jumpCardRulePage() src="../../assets/icon_right_arrow.png"/>
          </div>
          <!--        折扣次数-->
          <div class="div2">
            <div class="div-icon">
              <img class="div2-img" src="../../assets/icon_card_reduce.png"/>
              <p class="div2-p">优惠{{ cardDetail.totalLimit }}次</p>
            </div>
            <div class="div-icon">
              <img class="div2-img" src="../../assets/icon_card_times.png"/>
              <p class="div2-p">每天限{{ cardDetail.periodLimit }}次</p>
            </div>
            <div class="div-icon">
              <img class="div2-img" src="../../assets/icon_card_discount.png"/>
              <p class="div2-p">每次享{{ formatDiscount(cardDetail.discount) }}折</p>
            </div>
          </div>
        </div>
        <!--      有效期-->
        <div class="card2">
          <p class="card2-p1">{{ formatValidDate(cardDetail.grantTime, cardDetail.invalidDate) }}</p>
          <p class="card2-p2" @click="jumpToBillList()">使用记录</p>
        </div>
        <!--      使用次数-->
        <div class="card3">
          <div class="div-icon">
            <p class="card3-p1">{{ cardDetail.totalTimes }}</p>
            <p class="card3-p2">已使用次数</p>
          </div>
          <div class="line"></div>
          <div class="div-icon">
            <p class="card3-p1">{{ cardDetail.totalLimit - cardDetail.totalTimes }}</p>
            <p class="card3-p2">剩余次数</p>
          </div>
          <div class="line"></div>
          <div class="div-icon">
            <span class="card3-icon">¥</span><span class="card3-p1">{{
              formatMoney(cardDetail.totalDiscountAmount)
            }}</span>
            <p class="card3-p2">已优惠</p>
          </div>
        </div>


<!--      </div>-->

    </div>
    <!--      button-->
    <van-button class="van-button" v-if="cardDetail" type="primary" color="#0093FF" @click="jumpToRide()">去乘车</van-button>

    <p class="toOrder" @click = jumpOrderListPage()>查看已购</p>

    <!-- 没有数据 -->
    <div class="cardNoData" v-if="!cardDetail">
      <img
          src="../../assets/icon_no_card.png"
          alt=""
      />
      <div class="p1">暂无可用的公交折扣卡</div>
    </div>
  </div>
</template>

<script>
import {validCard} from "../../api/HttpService";
import moment from "moment";

export default {
  name: "cardDetail",
  mounted: function () {
    this.getData()
    document.title = "折扣卡";
  },
  data: function () {
    return {
      loading: false,
      cardDetail: null,
      isFromWXMP:localStorage.getItem("hostProgram") == "wxmp",
    };
  },
  methods: {
    // 获取数据
    getData: async function () {
      let params = {};
      await validCard(params)
          .then(({res}) => {
            this.cardDetail = res.data
          })
          .catch((err) => {
            console.log(err);
            this.$toast("网络异常");
          });
    },

    // 格式化折扣
    formatDiscount: function (discount) {
      switch (this.cardDetail.type) {
        case 10:
        case 20:
        case 40:
          return "";
        case 30:
          var money = Math.round(discount * 1000) / 100;
          return money;
        default:
          return "";
      }
    },
    //格式化有效期
    formatValidDate: function (invalidDate, grantTime) {
      moment.locale('zh-cn');
      let invalid = moment(invalidDate).format("ll");
      let grant = moment(grantTime).format("ll");
      return "有效期：" + invalid.substring(5,invalid.length) + " ~ " + grant.substring(5,grant.length)
    },

    //格式化金额
    formatMoney: function (money) {
      var value = Math.round(parseFloat(money)) / 100;
      var xsd = value.toString().split(".");
      if (xsd.length == 1) {
        value = value.toString() + ".00";
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
      return value;
    },

    jumpCardRulePage: function () {
      if (this.isFromWXMP){
        //小程序端的使用规则
        this.$router.push({
          name: "CardRulePage",
        });
      }else{
        //app端的使用规则
        var _url =
            window.location.host.match(/test/) == null
                ? "https://h5-lzgj.brightcns.com/static-v2/Month/useRules.html"
                : "https://lzgj.test.brightcns.cn/static-v2/Month/useRules.html";
        window.location.href = _url;
      }

    },

    jumpOrderListPage: function () {
      this.$router.push({
        name: "orderList",
      });
    },

    jumpToRide:function (){
      if (this.isFromWXMP){
        wx.miniProgram.getEnv(function (res) {
          if (res.miniprogram) {
            wx.miniProgram.navigateBack({
              delta: 1,
            });
          }
        })
      }else{
        window.location.href = "liangla://trans";
      }

    },

    jumpToBillList:function (){
      var url =
          window.location.host.match(/test/) == null
              ? "https://h5-lzgj.brightcns.com/dxlcbill/#/BillListPage?recordNo="+this.cardDetail.recordNo
              : "https://lzgj.test.brightcns.cn/dxlcbill/#/BillListPage?recordNo="+this.cardDetail.recordNo;
      window.location.href = url;
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../../page/cardDetail/cardDetail.scss";
</style>
